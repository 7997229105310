import { client } from '../../default.client';
import { productAttrAdapter } from '../../adapters/menu';
import { AxiosResponse } from 'axios';
import { BarcodeProductRaw, IBarcodeProduct } from '@/types/interfaces/menu';

export default function (
	filial_id: number,
	barcode: string
): Promise<AxiosResponse<IBarcodeProduct>> {
	return client
		.get<AxiosResponse<BarcodeProductRaw>>(
			`menu/v3/product-by-barcode?filial_id=${filial_id}&barcode=${barcode}`
		)
		.then(response => {
			return {
				...response.data,
				data: {
					...productAttrAdapter(response.data.data),
					id: response.data.data.id
				}
			};
		})
		.catch(error => {
			throw error;
		});
}
