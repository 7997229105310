import { client } from '../../default.client';
import { productsAdapter } from '../../adapters/menu';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces';
import { IProduct, ProductAttrRaw } from '@/types/interfaces/menu';

/**
 * Получение популярных блюд
 */
const getFavouriteProducts = (filialId: number, abort?: AbortController) => {
	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal
	};

	return client
		.get<ApiProxyResponse<IProduct<ProductAttrRaw>[]>>(
			'menu/v3/popular/products',
			{
				...config,
				params: {
					filial_id: filialId,
					limit: 20
				}
			}
		)
		.then(response => ({
			...response.data,
			data: productsAdapter(response.data.data)
		}))
		.catch(error => {
			throw error;
		});
};

/**
 * Получение популярных и любимых блюд
 */
const getFavouriteProductsAuth = (
	filialId: number,
	abort?: AbortController
) => {
	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal
	};

	return client
		.get<ApiProxyResponse<IProduct<ProductAttrRaw>[]>>(
			'menu/v3/favourite/products',
			{
				...config,
				params: {
					filial_id: filialId,
					limit: 20
				}
			}
		)
		.then(response => ({
			...response.data,
			data: productsAdapter(response.data.data)
		}))
		.catch(error => {
			throw error;
		});
};

export { getFavouriteProducts, getFavouriteProductsAuth };
