import { PROMO_CATEGORY, FREQUENT_CATEGORY } from '../constants';
import { ProductsCategory } from '../models';
import i18n from '@/utils/plugins/i18n';
import { IPromo, IPromoProduct } from '@/types/interfaces/common';
import { IProductsCategory } from '@/types/interfaces/menu';
import { PROMO_TYPES } from '../types';

interface CommonPromoAttributes {
	discount: string;
	productLimit: number;
	productDayLimit: number;
}

interface ExtendedPromoAttributes extends CommonPromoAttributes {
	promoId: string;
	promoPrice: number;
}

type PromoDiscountHash = Record<string, CommonPromoAttributes>;
type PromoProductsHash = Record<string, ExtendedPromoAttributes>;

export const createPromoHash = (
	promos: IPromo[],
	promoProducts: IPromoProduct[]
) => {
	const promoHash: PromoDiscountHash = {};
	const productsHash: PromoProductsHash = {};

	for (const promo of promos) {
		if (!promoHash[promo.id]) {
			promoHash[promo.id] = {
				discount: promo.attributes.discount,
				productLimit: promo.attributes.productLimit,
				productDayLimit: promo.attributes.productDayLimit
			};
		}
	}

	for (const product of promoProducts) {
		if (!productsHash[product.attributes.productId]) {
			const promo = promoHash[product.attributes.promoId];
			if (promo) {
				productsHash[product.attributes.productId] = {
					promoId: product.attributes.promoId,
					discount: promo.discount,
					promoPrice: product.attributes.price,
					productLimit: promo.productLimit,
					productDayLimit: promo.productDayLimit
				};
			}
		}
	}

	return productsHash;
};

export const getPromoPrice = (
	productPrice: number,
	discountPercentage: number
) => {
	return Math.floor((productPrice * (100 - discountPercentage)) / 100);
};

/**
 * Обработка акции для отдельных продуктов
 */
const handlePartialPromo = (
	promos: IPromo[],
	promoProducts: IPromoProduct[],
	menu: IProductsCategory[]
) => {
	const newMenu: IProductsCategory[] = JSON.parse(JSON.stringify(menu));
	const promoCategory = new ProductsCategory(
		PROMO_CATEGORY.id,
		i18n.global.t(PROMO_CATEGORY.translateKey)
	);

	const hash = createPromoHash(promos, promoProducts);

	newMenu.forEach(category => {
		category.relationships.forEach(product => {
			if (!product.attributes.promo_id) {
				const tempPromo = hash[product.id];
				if (tempPromo) {
					product.attributes.promo_id = tempPromo.promoId;
					product.attributes.discount = tempPromo.discount;
					product.attributes.promo_price = Math.floor(tempPromo.promoPrice);
					product.attributes.promo_limit = tempPromo.productLimit;
					product.attributes.promo_day_limit = tempPromo.productDayLimit;

					if (!promoCategory.hasProductById(product.id)) {
						promoCategory.addProduct(product);
					}
				}
			}
		});
	});

	if (promoCategory.hasProducts()) {
		const index = newMenu[0].id === FREQUENT_CATEGORY.id ? 1 : 0;
		newMenu.splice(index, 0, promoCategory.getCategory());
	}

	return newMenu;
};

/**
 * Обработка акции для всего меню
 */
const handleFullPromo = (promo: IPromo, menu: IProductsCategory[]) => {
	const newMenu: IProductsCategory[] = JSON.parse(JSON.stringify(menu));
	const discountPercentage = promo.attributes.discount_percentage || 0;

	newMenu.forEach(category => {
		category.relationships.forEach(product => {
			product.attributes.promo_id = promo.id;
			product.attributes.discount = promo.attributes.discount;
			product.attributes.promo_price = getPromoPrice(
				product.attributes.price,
				discountPercentage
			);

			product.attributes.promo_limit = promo.attributes.productLimit;
			product.attributes.promo_day_limit = promo.attributes.productDayLimit;
		});
	});

	return newMenu;
};

/**
 * Добавляет категорию акций в меню
 */
export default function (
	promos: IPromo[],
	promoProducts: IPromoProduct[],
	menu: IProductsCategory[]
) {
	if (!promos.length) {
		return menu;
	}

	const fullPromo = promos.find(
		p => p.attributes.type === PROMO_TYPES.FULL_MENU
	);

	if (fullPromo) {
		return handleFullPromo(fullPromo, menu);
	}

	return handlePartialPromo(promos, promoProducts, menu);
}
