import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces/api-proxy-response-data';
import { menuCategoriesAdapter } from '../../adapters/menu';
import { MenuCategories } from '@/types/interfaces/menu';

/**
 * Получение категорий меню
 */
export default function (
	filialId: number,
	abort?: AbortController
): Promise<ApiProxyResponse<MenuCategories>> {
	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal
	};

	return client
		.get(`menu/v2/category/products?filial_id=${filialId}`, { ...config })
		.then(response => ({
			...response.data,
			data: menuCategoriesAdapter(response.data.data)
		}))
		.catch(error => {
			throw error;
		});
}
