import { client } from '../../default.client';
import { productsAdapter } from '../../adapters/menu';
import { JSON_API_HEADER } from '../../constants';
import { IProduct } from '@/types/interfaces/menu';

/**
 * Получение связанных продуктов
 */
export default function (
	filialId: number,
	productIds: number[]
): Promise<IProduct[]> {
	return client
		.get('menu/v3/cross-sell/products', {
			params: { filial_id: filialId, products: productIds },
			headers: JSON_API_HEADER
		})
		.then(response => {
			return productsAdapter(response.data.data);
		})
		.catch(error => {
			throw error;
		});
}
