import { ProductSize } from '@/types/interfaces/menu';
import { ICartPosition, ICartAdditiveGroup } from '../types';
import { getFormattedAdditives } from '@/services/cart/functions';

export default class implements ICartPosition {
	product_id: number;
	name: string;
	description: string;
	price: number;
	image_url: string;
	additive_groups: ICartAdditiveGroup[];
	amount: number;
	is_stopped: boolean;
	is_unavailable: boolean;
	is_cross_sell: boolean;
	promo_id: string | null;
	promo_price: number | null;
	discount: string | null;
	promo_limit: number | null;
	promo_day_limit: number | null;
	size?: ProductSize;

	constructor(properties: ICartPosition) {
		this.product_id = properties.product_id;
		this.name = properties.name;
		this.description = properties.description;
		this.price = properties.price;
		this.image_url = properties.image_url;
		this.additive_groups = properties.additive_groups;
		this.amount = properties.amount;
		this.is_stopped = properties.is_stopped;
		this.is_unavailable = properties.is_unavailable;
		this.is_cross_sell = properties.is_cross_sell;
		this.promo_id = properties.promo_id;
		this.promo_price = properties.promo_price;
		this.discount = properties.discount;
		this.promo_limit = properties.promo_limit;
		this.promo_day_limit = properties.promo_day_limit;
		this.size = properties.size;
	}

	/**
	 * Возвращает новый объект из свойств
	 */
	getProperties(): ICartPosition {
		return {
			product_id: this.product_id,
			name: this.name,
			description: this.description,
			price: this.price,
			image_url: this.image_url,
			additive_groups: this.additive_groups,
			amount: this.amount,
			is_stopped: this.is_stopped,
			is_unavailable: this.is_unavailable,
			is_cross_sell: this.is_cross_sell,
			promo_id: this.promo_id,
			promo_price: this.promo_price,
			discount: this.discount,
			promo_limit: this.promo_limit,
			promo_day_limit: this.promo_day_limit,
			size: this.size
		};
	}

	/**
	 * Возвращает название
	 */
	getName(): string {
		return this.name;
	}

	/**
	 * Возвращает количество
	 */
	getAmount(): number {
		return this.amount;
	}

	/**
	 * Имеет ли позиция акцию
	 */
	hasPromo(): boolean {
		return !!this.promo_id;
	}

	/**
	 * Получить цену дополнений позиции
	 */
	getAdditivesPrice(): number {
		return getFormattedAdditives(this.additive_groups).reduce(
			(acc: any, add: any) => acc + add.price * add.count,
			0
		);
	}

	/**
	 * Получить актуальную цену позиции
	 */
	getActualPrice(): number {
		return this.size?.price || this.promo_price || this.price;
	}

	/**
	 * Получить полную актуальную цену позиции
	 */
	getActualTotalPrice(): number {
		return this.getActualPrice() + this.getAdditivesPrice();
	}

	/**
	 * Получить актуальную цену с учетом количества позиции
	 */
	getActualTotalPriceWithAmount(): number {
		return this.getActualTotalPrice() * this.getAmount();
	}

	/**
	 * Получить полную первоначальную цену позиции
	 */
	getInitialTotalPrice(): number {
		return this.price + this.getAdditivesPrice();
	}

	/**
	 * Получить полную первоначальную цену с учетом количества позиции
	 */
	getInitialTotalPriceWithAmount(): number {
		return this.getInitialTotalPrice() * this.getAmount();
	}

	/**
	 * Получить разницу между первоначальной и скидочной ценами
	 */
	getPromoDiffTotalPrice(): number {
		if (!this.promo_price) {
			return 0;
		}

		return this.price - (this.promo_price || 0);
	}

	/**
	 * Получить разницу между первоначальной и скидочной ценами с учетом количества позиции
	 */
	getPromoDiffTotalPriceWithAmount(): number {
		return this.getPromoDiffTotalPrice() * this.getAmount();
	}
}
