<template>
	<Component :is="layout" v-if="isReady" class="app">
		<RouterView v-slot="{ Component }">
			<KeepAlive :include="['Menu']">
				<Component :is="Component" />
			</KeepAlive>
		</RouterView>

		<BaseNavigation v-if="isNavbarVisible" />
		<DebugBar v-if="isDebugBarVisible" />
		<PartnerForceUpdateWidget v-if="isForceUpdate" />
	</Component>

	<!-- Bottom Sheet (Свитчер языка) -->
	<UiBottomSheet
		ref="languageSheet"
		shift-color="#D4D3DF"
		closeable
		@closed="closeLanguageSheet"
	>
		<template #title>{{ $t('pickLang') }}</template>
		<div class="py-6 px-4">
			<BaseLangPicker source="main" @close="closeLanguageSheet(true)" />
		</div>
	</UiBottomSheet>
</template>

<script lang="ts">
import { ref, computed, defineAsyncComponent, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import PartnerForceUpdateWidget from '@/components/partner/PartnerForceUpdateWidget.vue';
import BaseLangPicker from '@/components/base/BaseLangPicker.vue';
import { ClientService } from '@/services/client';
import { AuthService } from '@/services/auth';
import { UserService } from '@/services/user';
import { Analytics, events } from '@/services/analytics';
import { useApp, useNavbar, useLanguage } from '@/utils/composables';
import { needToDetermineLanguage } from '@/utils/plugins/i18n';
import {
	getQueryByName,
	hasAuthSilentError,
	injectStrict
} from '@/utils/functions';
import { AUTH_FAILED_TEXT, NATIVE_APP_AGENT } from '@/utils/constants';
import { NotificationKey, ProvidersKey, LoaderKey } from '@/types/symbols';
import { isError } from '@/types';

const appEnv = import.meta.env.VITE_ENV;

const isDebugBarVisible =
	!['production', 'local'].includes(appEnv) ||
	getQueryByName('debugbar') === 'true';

const BaseNavigation = defineAsyncComponent(
	() => import('@/components/base/BaseNavigation')
);

const DebugBar = defineAsyncComponent(
	() => import('@/components/debug/DebugBar.vue')
);

export default {
	name: 'App',
	components: {
		PartnerForceUpdateWidget,
		BaseNavigation,
		DebugBar,
		BaseLangPicker
	},
	setup() {
		const { initTown, initPlatformAndVersion, initService } = useApp();
		const { isNavbarVisible } = useNavbar();
		const { updateLanguage } = useLanguage();

		const { t } = useI18n();

		const isReady = ref(false);
		const notification = injectStrict(NotificationKey);
		const loader = injectStrict(LoaderKey);
		const providers = injectStrict(ProvidersKey);
		const route = useRoute();
		const isAuthPage = window.location.pathname.includes('auth');
		const layout = computed(() => route.meta.layout || DefaultLayout);

		const checkIsForceUpdate = () => {
			if (window.location.host !== 'mamma-mia.choco.kz') {
				return false;
			}

			const version = providers.application.getCustomConfig()?.version;
			if (version && ['1.1.0', '2.1.0'].includes(version)) {
				return true;
			}

			if (navigator.userAgent.includes(NATIVE_APP_AGENT)) {
				return false;
			}

			return true;
		};

		const isForceUpdate = checkIsForceUpdate();
		let isSlowInternetConnectionShown = false;

		const languageSheet = ref<UiBottomSheet | null>(null);
		const openLanguageSheet = () => languageSheet.value?.open();
		const closeLanguageSheet = (manual = false) => {
			if (manual) {
				languageSheet.value?.hide();
			}

			isReady.value = true;
		};

		const initLanguage = async () => {
			const tempLanguage = getQueryByName('language');
			if (AuthService.isAuthorized() && !isAuthPage) {
				try {
					const data = await UserService.fetchProfile().catch(() => {
						console.log('Error while fetching user profile');
						return null;
					});
					const language = data?.data.attributes?.language;

					if (language === tempLanguage) {
						return updateLanguage(language);
					}

					if (tempLanguage) {
						return updateLanguage(tempLanguage, true);
					}

					if (language) {
						return updateLanguage(language);
					}
				} catch {
					console.log('Error while updating user profile');
				}
			}

			if (tempLanguage) {
				updateLanguage(tempLanguage);
			}
		};

		const onSlowInternetConnection = () => {
			// Чтобы постоянно не показывать
			if (isSlowInternetConnectionShown) {
				return;
			}

			notification.error(t('internet.connection'));
			isSlowInternetConnectionShown = true;
		};

		providers.application.setLoader(loader);
		ClientService.setGeoRejectedCallback(() => {
			notification.show(t('geo.geoRejectedText'));
		});

		// определение высоты viewport для мобильных устройств
		const dynamicAppHeight = () => {
			const doc = document.documentElement;
			doc.style.setProperty('--dynamic-app-height', `${window.innerHeight}px`);
		};

		const authorize = async () => {
			const trackId = getQueryByName('track_id');
			const code = getQueryByName('code');

			if (code && ClientService.isWebView()) {
				const isExecuted = await AuthService.authByCode(code);
				return isExecuted;
			}

			if (trackId) {
				const isExecuted = await AuthService.authByTrackId(trackId);
				return isExecuted;
			}

			if (AuthService.isAuthorized()) {
				if (ClientService.isWebView()) {
					AuthService.logout();
				}

				const userId = AuthService.getUserId();
				if (userId) {
					providers.application.setUserId(userId);
				}
			}

			return false;
		};

		initTown();
		initPlatformAndVersion();
		initService();

		return {
			isReady,
			layout,
			isForceUpdate,
			isNavbarVisible,
			isDebugBarVisible,
			languageSheet,
			initLanguage,
			onSlowInternetConnection,
			dynamicAppHeight,
			authorize,
			openLanguageSheet,
			closeLanguageSheet
		};
	},

	// globalProperties нельзя вытащить в Сomposition API,
	// поэтому приходится писать часть кода в mounted()
	async mounted() {
		if (this.dynamicAppHeight && typeof this.dynamicAppHeight === 'function') {
			window.addEventListener('resize', this.dynamicAppHeight);
			this.dynamicAppHeight();
		}

		try {
			const initMetrika = (callback = () => {}) => {
				if (this.$metrika) {
					return callback();
				}

				const yandexKey = import.meta.env.VITE_YANDEX_KEY;
				document.addEventListener(`yacounter${yandexKey}inited`, callback);
			};

			const setUserIdToMetrika = () => {
				const userId = AuthService.getUserId();
				if (userId) {
					this.$metrika.userParams({ UserId: userId });
				}
			};

			this.$loader.on();
			await this.authorize();
			await this.initLanguage();
			initMetrika(setUserIdToMetrika);
			this.$loader.off();

			Analytics.logEvent(events.MINI_APP_OPENED);
		} catch (error) {
			this.$loader.off();
			if (isError(error)) {
				if (hasAuthSilentError(error.message)) {
					console.warn(AUTH_FAILED_TEXT);
				} else {
					this.$notification.error(error.message);
				}
			}
		} finally {
			if (needToDetermineLanguage()) {
				this.openLanguageSheet();
			} else {
				this.isReady = true;
			}
		}

		window.addEventListener('offline', this.onSlowInternetConnection);
	},

	beforeUnmount() {
		if (this.dynamicAppHeight && typeof this.dynamicAppHeight === 'function') {
			window.removeEventListener('resize', this.dynamicAppHeight);
		}

		window.removeEventListener('offline', this.onSlowInternetConnection);
	}
};
</script>

<style scoped>
.app {
	height: calc(var(--dynamic-app-height));
}
</style>
