import { IBill } from '@/services/bill/types';
import { IBillDetails } from '@/types/interfaces/orders';

export default function getBillAdapter(bill: IBill): IBillDetails {
	const orderItems = bill.order_items.map((item, index) => {
		const modifiers = item.modifiers.map((modifier, modifierIndex) => {
			return {
				id: modifierIndex,
				type: '',
				attributes: {
					amount: modifier.amount,
					name: modifier.name,
					price: modifier.total,
					refunded_amount: 0
				}
			};
		});

		return {
			id: index,
			type: '',
			attributes: {
				name: item.title,
				amount: item.amount,
				price: +item.total,
				order_item_id: 0,
				price_without_promo: 0,
				refunded_amount: 0
			},
			relationships: {
				refunded_items: [],
				images: [
					{
						id: index,
						type: '',
						attributes: { image_url: '' }
					}
				],
				item_additives: modifiers
			}
		};
	});

	const discount_data = bill.discount_data;

	return {
		id: bill.id,
		type: '',
		main_terminal_id: bill.main_terminal_id,
		attributes: {
			...bill,
			additional_message: '',
			comment: '',
			created_at: bill.order_created_at,
			rahmet_order_id: bill.rahmet_order_id,
			current_order_status: {
				type: 1,
				text: '',
				comment: '',
				created_at: '',
				seconds_left: 0
			},
			service_type: 0,
			service_price: 0,
			discount_data,
			total_price: bill.total,
			table_number: bill.table_number,
			waiter_name: bill.waiter_name,
			amount: bill.price,
			order_number: bill.order_number,
			waiter_id: bill.waiter_id,
			waiter_tip_id: bill.waiter_tip_id,
			is_cash_payment_type_applied: false,
			external_id: bill.external_id,
			is_vendor_delivery: false
		},
		relationships: {
			filial: {
				id: 0,
				type: '',
				attributes: {
					logotype_url: '',
					name: ''
				}
			},
			order_items: orderItems
		}
	};
}
