<template>
	<ul class="px-4">
		<li
			v-for="(value, i) in values"
			:key="i"
			class="flex items-center py-3 border-b border-solid border-grey-lite"
			data-test-name="history-item"
			@click="$emit('select', value)"
		>
			<span
				class="autocomplete-search flex-shrink-0 w-6 h-6 mr-2 bg-contain bg-no-repeat"
			></span>
			<span class="font-sirius text-base">
				{{ value }}
			</span>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'SearchAutocomplete',
	props: {
		values: {
			type: Array,
			default: () => []
		}
	},
	emits: ['select']
};
</script>

<style lang="scss" scoped>
.autocomplete-search {
	background-image: url('@/assets/icons/search-redesign.svg');
}
</style>
